<template>
  <div class="content-right rounded-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
        </li>
        <li class="breadcrumb-item active">Inventory Managements</li>
      </ol>
    </nav>
    <div
      class="content-body"
      id="fullHeight"
      style="min-height: calc(100vh - 135px)"
    >
      <div class="row align-items-center">
        <div class="col-5">
          <h2 class="title2">Inventory</h2>
        </div>
        <div class="col text-end">
          <router-link
            :to="{ name: 'add-item' }"
            class="btn btn-outline-primary"
            >Add</router-link
          >
        </div>
      </div>
      <div class="t-layout">
        <div class="row">
          <div class="col-md-12">
            <div v-if="this.$store.state.loader">
              <loader
                object="#f74b3d"
                color1="#ffffff"
                color2="#17fd3d"
                size="5"
                speed="2"
                bg="#343a40"
                objectbg="#999793"
                opacity="80"
                disableScrolling="false"
                name="spinning"
              ></loader>
            </div>
            <div v-else class="card">
              <div class="card-header">
                <flash-message class="myCustomClass"></flash-message>
              </div>
              <div class="card-body">
                <div class="custom-pagination">
                  <div class="row">
                    <div class="col-md-3">
                      <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="Search data"
                      ></b-form-input>
                    </div>
                    <div class="col-md-9 tx-right">
                      <div class="page-number-input custom-box">
                        <label>Go to Page:</label>
                        <b-form-input
                          class="page-number"
                          v-model="currentPage"
                          type="number"
                          min="1"
                          :max="
                            inventoryData.length > 0
                              ? Math.ceil(inventoryData.length / perPage)
                              : 1
                          "
                        ></b-form-input>
                      </div>
                      <div class="pagination-wrapper custom-box">
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="inventoryData.length"
                          :per-page="perPage"
                          aria-controls="my-table"
                        ></b-pagination>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="table-responsive">
                  <b-table
                    class="table table-custom"
                    :items="inventoryData"
                    :fields="fields"
                    :per-page="perPage"
                    :current-page="currentPage"
                    responsive="sm"
                    :filter="filter"
                    show-empty
                  >
                    <template v-slot:cell(accessory)="data">
                      {{ data.item.accessory_name }}
                    </template>
                    <template v-slot:cell(model)="data">
                      {{ data.item.model }}
                    </template>
                    <template v-slot:cell(action)="data">
                      <div class="d-inline-block">
                        <div class="d-flex justify-content-md-around">
                          <router-link
                            :to="{
                              name: 'edit-inventory-item',
                              params: { id: data.item.id },
                            }"
                            class="button button1"
                            title="Edit"
                            ><i class="icon-pencil"></i
                          ></router-link>
                        </div>
                      </div>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import axios from "axios";
export default {
  data() {
    return {
      currentPage: 1,
      perPage: 15,
      filter: null,
      fields: [
        {
          key: "accessory",
          label: "Item Name",
          tdAttr: { "custom-label": "Accessory:" },
        },
        {
          key: "model",
          tdAttr: { "custom-label": "Model:" },
        },
        {
          key: "quantity",
          tdAttr: { "custom-label": "Quantity:" },
        },
        {
          key: "status",
          tdAttr: { "custom-label": "Status:" },
        },
        {
          key: "action",
          tdAttr: { "custom-label": "Action:" },
        },
      ],
    };
  },
  computed: {
    inventoryData() {
      return this.$store.state.inventoryData;
    },
  },
  created() {
    this.$store.dispatch("loadInventory");
  },
  methods: {
    formatDate(dateSting) {
      return moment(String(dateSting)).format("YYYY-MM-DD");
    },
    /* async deleteassignment(id){
          if(confirm("Are you sure you want to delete this accessory assingment?")){
            await axios.get('accessories/delete/'+id)
            this.$store.dispatch('loadAssignedAccessories');
            this.flashMessage.success({
              message: 'Employee deleted successfully',
              time: 3000
            });
            this.$store.dispatch('loadEmployees');
          }
        } */
  },
};
</script>
